body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
* {
  box-sizing: border-box;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.container.mt-4 {
  min-height: calc(100svh - 272px);

  @media (max-width: 1200px) {
    min-height: calc(100svh - 296px);
  }

  @media (max-width: 992px) {
    min-height: calc(100svh - 320px);
  }

  @media (max-width: 767.9px) {
    min-height: calc(100svh - 488px);
  }

  @media (max-width: 540px) {
    min-height: auto;
    margin-bottom: 40px;
  }

  h2{
    @media (max-width: 575.9px) {
      font-size: 28px;
    }
  }
}

::-webkit-scrollbar {
  width: 0.5em;
}

::-webkit-scrollbar-thumb {
  background: #343a40;
  border-radius: 0 0 10em 10em;
}

.rodal .rodal-dialog {
  width: 600px !important;
  height: 330px !important;
  background: rgb(242, 242, 242);
  padding: 0 !important;
  top: -10%;
  border-radius: 12px;
  overflow: hidden;

  @media (max-width: 575.9px) {
    width: 460px !important;
    height: 360px !important;
  }

  @media (max-width: 500px) {
    width: 360px !important;
    height: 460px !important;
  }

  @media (max-width: 400px) {
    width: 300px !important;
    height: 490px !important;
  }

  .company {
    padding: 20px;

    .title h4 {
      font-size: 20px;
    }

    .valid {
      display: flex;
      align-items: center;

      P {
        margin: 0 0 0 10px;
      }
    }
  }

  .wrapper {
    border-top: 1px dashed #b7d4e8;
    padding: 20px;
    background: #eceff1;
    padding-bottom: 30px;

    .promocode {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: white;
      border-radius: 8px;
      margin: 10px 0;

      input {
        width: calc(100% - 40px);
        border: none;
        text-align: center;
        height: 40px;
        font-size: 24px;
        font-weight: 700;
        text-transform: uppercase;
        background: transparent;
        cursor: default;
      }

      button {
        all: unset;
        width: 40px;
        height: 40px;
        display: flex;
        align-items: center;
        justify-content: center;
        background: rgb(116, 169, 255);
        color: white;
        border-radius: 8px;
        cursor: pointer;
      }
    }

    P {
      font-size: 15px;
      text-align: center;

      &:nth-child(1) a {
        text-transform: capitalize;
      }
    }
  }
}

.card.promo {
  width: 260px;
  overflow: hidden;

  @media (max-width: 767.9px) {
    width: 239px;
  }

  @media (max-width: 575.9px) {
    width: 100%;
  }
}
